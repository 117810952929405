const gSelector = {
	html: document.querySelector('html'),
	body: document.querySelector('body'),
	header: document.querySelector('.l-header'),
	headerOpenClass: 'is-navi-open',
};
const breakPoint = {
	a: 768,
	b: 767
};
const windowSize = {
	width: window.innerWidth,
	height: window.innerHeight
};


// ユーザーエージェント関連
const deviceCategory = {
	mobile: "ua_mobile",
	tablet: "ua_tablet",
	pc: "ua_pc"
};
const deviceParser = () => {
	const ua = navigator.userAgent;
	if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
		// スマホ
		gSelector.html.classList.add('ua_mobile');
		return deviceCategory.mobile;
	} else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
		// タブレット
		gSelector.html.classList.add('ua_tablet');
		document.querySelector('meta[name=viewport]').setAttribute('content', 'width=1300,user-scalable=yes,maximum-scale=2.0');
		return deviceCategory.tablet;
	} else{
		// PC
		gSelector.html.classList.add('ua_pc');
		return deviceCategory.pc;
	}
};
const device = deviceParser();
// example
// if(device == deviceCategory.mobile) console.log("mobile");


// アンカースクロール処理
class AnchorScroll {
	constructor(target) {
		const rect = target.getBoundingClientRect().top;
		const offset = window.pageYOffset;
		const gap = gSelector.header.offsetHeight;
		const position = rect + offset - gap;
		window.scrollTo({
			top: position,
			behavior: 'smooth',
		});
	}
}


// ヘッダーメニュー開閉
class HeaderMenu {
	constructor() {
		if(device == deviceCategory.pc) return;
		gSelector.body.classList.toggle(gSelector.headerOpenClass);
	}
}


// ロード後クラス追加
(function(){
	window.addEventListener('load', () => {
		gSelector.body.classList.add('is-loaded');
	});
})();


// ヘッダーまわり
(function(){
	// メニュー開閉
	const humberger = document.querySelector('.l-header__humberger');
	if(humberger) {
		humberger.addEventListener('click', function(){
			const humbergerToggle = new HeaderMenu;
		}, false);
	}
	// 現在位置クラス付与
	const menuLink = document.querySelectorAll('.l-header__menu__item');
	const menuCurrent = 'is-current';
	const menuCurrentTarget = document.getElementsByClassName(menuCurrent);
	for (let i = 0; i < menuLink.length; i++){
		const menuHref = menuLink[i].querySelector('a').getAttribute('href');
		if(location.pathname == "/") {
			menuLink[0].classList.add(menuCurrent);
		} else if(location.pathname == "/contact/") {
			menuLink[i].classList.remove(menuCurrent);
		} else {
			if((location.pathname).match(menuHref)) {
				menuLink[i].classList.add(menuCurrent);
			} else {
				menuLink[i].classList.remove(menuCurrent);
			}
		}
	}	
	// 下層ページでトップもクラス付与されてしまうのを解消
	for (let i = 0; i < menuCurrentTarget.length; i++){
		if(i >= 1) {
			menuCurrentTarget[0].classList.remove(menuCurrent);
		}
	}
	// 少しスクロールしたら背景同化防止のクラスをつける
	const headerSeparate = () => {
		let scrollY = window.pageYOffset;
		const classAddPosition = 150;
		const separateClass = 'is-separate';
		scrollY > classAddPosition ? gSelector.header.classList.add(separateClass): gSelector.header.classList.remove(separateClass); 
	}
	headerSeparate();
	window.addEventListener('scroll', () => {
		headerSeparate();
	});
})();


// アンカーリンク
(function(){
	const scrollTrigger = document.querySelectorAll('a[href^="#"]');
	for (let i = 0; i < scrollTrigger.length; i++){
		scrollTrigger[i].addEventListener('click', (e) => {
			e.preventDefault();
			let href = scrollTrigger[i].getAttribute('href');
			let targetElement = document.getElementById(href.replace('#', ''));
			const defaultAnchor = new AnchorScroll(targetElement);
		});
	}	
	// ページをまたぐアンカーリンク
	const anchorTrigger = document.querySelectorAll('a[data-anchor]');
	for (let i = 0; i < anchorTrigger.length; i++){
		anchorTrigger[i].addEventListener('click', (e) => {
			e.preventDefault();
			const data = anchorTrigger[i].getAttribute('data-anchor');
			const anchor = data.split('?');
			const anchorPath = anchor[0];
			const anchorId = anchor[1].split('=')[1];
			const anchorTarget = document.getElementById(anchorId);
			if(anchorPath == location.pathname) {
				// ページが同じならスクロール
				const pageAnchor = new AnchorScroll(anchorTarget);
				// URLに挿入する
				history.replaceState('','',data);
				// メニュー閉じる
				const anchorHeaderClose = new HeaderMenu;
			} else {
				// ページが異なるなら該当ページへ移動させる
				location.href = data;
			}
		});
	}	
	// アクセス時にanchorが存在したらスクロールさせる
	window.addEventListener('load', () => {
		if(document.URL.match(/anchor/)) {
			const data = location.href;
			const anchor = data.split('?');
			const anchorId = anchor[1].split('=')[1];
			const anchorTarget = document.getElementById(anchorId);
			const gap = gSelector.header.offsetHeight;
			const loadAnchor = new AnchorScroll(anchorTarget);
		}
	});
})();


// 要素が見えたらクラス追加
(function(){	
document.addEventListener('DOMContentLoaded', function() {	
	//監視したい要素を取得
	const target = document.querySelectorAll('.js-view-observer')
	const targetArray = Array.prototype.slice.call(target);
	//オプション設定
	const options = {
			root: null,
			rootMargin: "-30% 0px", 
			threshold: 0
	};
	//Intersection Observerを呼ぶ
	const observer = new IntersectionObserver(callback, options)
	targetArray.forEach((tgt) => {
		observer.observe(tgt)
	});
	//要素が交差したときの処理
	function callback(entries) {
		entries.forEach(function(entry, i) {
			const target = entry.target;
			if (entry.isIntersecting && !target.classList.contains('is-active')) {
				const delay = i * 100
				setTimeout(function(){
					target.classList.add('is-active');
				},delay);
			}
		});
	};
});
})();


// フォーム関連
(function(){	
	// 同意チェック
	const agreeCheck = () => {
		const agreeInput = document.querySelector('.js-agree-check');
		const agreeButton = document.querySelector('.js-agree-button');
		const agreeButtonClass = 'm-button';
		const agreeDisbiled = 'm-button--disbiled';
		if(!agreeInput && !agreeButton) return;
		if(!agreeButton.classList.contains(agreeButtonClass)) return;
		// クラス付与処理
		const agreeClassSet = () => {
			agreeInput.checked ? agreeButton.classList.remove(agreeDisbiled) : agreeButton.classList.add(agreeDisbiled);
		}
		// 表示時
		agreeClassSet();
		// inputクリック時
		agreeInput.addEventListener('click', (e) => {
			agreeClassSet();
		});
	}
	agreeCheck();
	// 都道府県の選択状態を記憶する
	const prefTrigger = document.querySelector('.js-pref-check');
	const prefSelect = document.querySelector('.js-pref-select');
	if(prefTrigger && prefSelect) {
		prefSelect.value = prefTrigger.value;
	}
	// AjaxZip3 表示時も起動 ※デフォルトでは入力時のみ
	const AjaxZip3code = document.querySelector('.p-country-name');
	const AjaxZip3Target = document.querySelector('input[name="zipcode"]');
	if(AjaxZip3code && AjaxZip3Target) {
		AjaxZip3.zip2addr('zipcode','pref','address');
	}
	// エラー画面のときはフォーム位置までスクロール
	const contactCheck = document.querySelector('.js-agree-check:checked');
	const contactPos = document.querySelector('.js-form-scroll');
	if(contactCheck && contactPos) {
		const contactScroll = new AnchorScroll(contactPos);
	}
})();


// 記事関連
(function(){
	// メニュー
	const articleMenuTrigger = document.querySelectorAll('.js-article-menu-toggle');
	const articleMenuActiveClass = 'is-active';
	Array.from(articleMenuTrigger).forEach(function(e) {
		e.addEventListener('click', () => {
			e.classList.toggle(articleMenuActiveClass);
			e.nextElementSibling.classList.toggle(articleMenuActiveClass);
			return false;
		});
	});
	// ページャー
	const articlePager = document.querySelector('.m-pagination .wp-pagenavi');
	const articlePrev = document.querySelector('.m-pagination .previouspostslink');
	const articleNext = document.querySelector('.m-pagination .nextpostslink');
	if(articlePager && !articlePrev) {
		let articlePrevElement = document.createElement('span');
		articlePrevElement.className = 'previouspostslink disabled';
		articlePrevElement.textContent = 'Prev';
		articlePager.appendChild(articlePrevElement);
	} else if(articlePager && !articleNext) {
		let articleNextElement = document.createElement('span');
		articleNextElement.className = 'nextpostslink disabled';
		articleNextElement.textContent = 'Next';
		articlePager.appendChild(articleNextElement);
	}
	// メニュー内カレント付与
	const articlemenuLink = document.querySelectorAll('a.m-article-content__menu__link');
	const articlemenuCurrent = 'is-current';
	const windowDomain = location.protocol+"//"+location.host;
	const windowQuery = location.search;
	if(articlemenuLink) {
		Array.from(articlemenuLink).forEach(function(e) {
			const href = e.href;
			const href_path = href.replace(windowDomain, '').replace(windowQuery, '');
			if(!href) return;
			if(!(location.pathname).match(href_path)) return;
			e.classList.add(articlemenuCurrent);
		});
	}
	const articlemenuChildLink = document.querySelectorAll('.m-article-content__menu__child-list a.m-article-content__menu__link');
	if(articlemenuChildLink) {
		Array.from(articlemenuChildLink).forEach(function(e) {
			if(e.classList.contains(articlemenuCurrent)) {
				e.parentElement.parentElement.previousElementSibling.classList.add(articlemenuCurrent);
			}
		});
	}
})();


// topページ
(function(){	
	// blogカルーセル
	window.addEventListener('DOMContentLoaded', () => {
		top_blog_swiper()
	});
	window.addEventListener('resize', () => {
		top_blog_swiper()
	});
	const top_blog_swiper = () => {
		let swiper; 
		if (windowSize.width <= breakPoint.b) {
			if (swiper) {
				return;
			} else {
				swiper = new Swiper(".top-blog__list", {
						navigation: {
						nextEl: ".top-blog__arrow__button--next",
						prevEl: ".top-blog__arrow__button--prev",
					},
				});
			}
		} else {
			if (swiper) {
				swiper.destroy();
				swiper = undefined;
			} 
		} 
	}
})();